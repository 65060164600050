// When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size
// window.onscroll = function() {scrollFunction()};

$(document).on('turbolinks:load', function(){
  $(".property-option-item").on('click', function(){
    $(".selected-property-option").text($(this).text());
    // modal is based on the button's value
    $(".find-a-service-btn").attr('data-target', "#" + $(this).attr('value'));
  });

  $(".dropdown-property-item").on('click', function(){
    var selectedValue = $(this).attr('value');

    $(".selected-property-type").text($(this).text());
    $(".selected-property").text(selectedValue);
    // these below are for the homepage dropdowns
    $(".selected-property-option").text("What do you want done?");
    $(".find-a-service-btn").removeAttr('data-target');

    togglePropertyOptions(selectedValue);
  });

  $('.property_type-option-item').on('click', function(){
    $(".selected-property_type").text($(this).text());
    $("#property_type").val($(this).val());
    $(this).parent().parent().parent().removeClass('required');
  });

  $('.frequency-option-item').on('click', function(){
    $(".selected-frequency").text($(this).text());
    $("#frequency").val($(this).val());
    $(this).parent().parent().parent().removeClass('required');
  });

  $('.city-option-item').on('click', function(){
    $(".selected-city").text($(this).text());
    $("#city").val($(this).val());
    $(this).parent().parent().parent().removeClass('required');
  });

  function togglePropertyOptions(selectedValue) {
    $(".property-option-menu").removeClass('d-none');

    if (selectedValue == 'Residential') {
      $(".residential_options").removeClass('d-none');
      $(".commercial_options").addClass('d-none');
    } else if (selectedValue == 'Commercial') {
      $(".commercial_options").removeClass('d-none');
      $(".residential_options").addClass('d-none');
    }
  }

  $(".project-cover-photo").click(function(e) {
    $.get($(this).data("href"), function(data) {});
  });

  $(".quote-type-box").click(function(e) {
    $('#select-quote-type').find('.quote-type-box').each(function(i, e){
      if ($(e).hasClass('active')) {
        $(e).removeClass('active');
      }
    });

    $(this).addClass('active');
    $('#selected-type').val($(this).attr('id'));
    $('.quote-type-button').removeAttr('disabled');
  });

  $('.quote-type-button').click(function(e) {
    var loc = $('#selected-type').val() === 'maid' ? '/quotes/quick' : '/quotes/custom?service_type=' + $('#selected-type').val();
    Turbolinks.visit(loc)
  });

  $('#quick-quote-next').click(function(e) {
    current_fieldset = $("#quick_quote_step_1");
    next_fieldset = $("#quick_quote_step_2");

    $("#quote-step").html("2");

    current_fieldset.hide();
    next_fieldset.show();
  });

  $('#quick-quote-back').click(function(e) {
    current_fieldset = $("#quick_quote_step_2");
    next_fieldset = $("#quick_quote_step_1");

    $("#quote-step").html("1");

    current_fieldset.hide();
    next_fieldset.show();
  });

  $('.spinner-button-dec').click(function(e) {
    spinner_input = $(this).next()[0];
    current_value = parseInt(spinner_input.value)
    new_value = current_value < 1 ? 0 : current_value - 1;
    spinner_input.value = new_value.toString();

    if (new_value < 1) {
      $(this).prop('disabled', true)
    }
  });

  $('.spinner-button-inc').click(function(e) {
    spinner_input = $(this).prev()[0];
    new_value = parseInt(spinner_input.value) + 1;
    spinner_input.value = new_value.toString();

    if (new_value > 0) {
      $(this).prev().prev().prop('disabled', false)
    }
  });

  $(".spinner_value").on('change', function(){
    spinner_dec = $(this).prev()[0];
    if (parseIntt($(this).value) < 1) {
      spinner_dec.prop('disabled', true);
    } else {
      spinner_dec.prop('disabled', false);
    }
  });

  $("#quick-quote-deep").click(function(e) {
    $("#quick-quote-standard").removeClass("active");
    $("#quick-quote-deep").addClass("active");   
    window.$("#deep-clean-options").modal('show');
  });

  $("#quick-quote-standard").click(function(e) {
    $("#quick-quote-standard").addClass("active");
    $("#quick-quote-deep").removeClass("active");   
    window.$("#standard-works-modal").modal('show');
  });

  $(function() {
    var $window = $(window);

    function deviceWidth() {
      return $window.width();
    } 

    function checkRequired() {
      $('.input-group').removeClass('required');
      $('.input-group > input:invalid').parent().addClass('required');
      $('.input-group > input:invalid').parent().addClass('required');
      $('.input-group.quote-dropdown-wrapper > .custom-dropdown > input:invalid').parent().parent().addClass('required');
      $('.input-group .quote-dropdown select:invalid').parent().parent().parent().addClass('required');
    };

    function checkValidity() {
      checkRequired();

      return (($('form').find('input:invalid').length == 0) && ($('form').find('select:invalid').length == 0))
    }

    function checkStepOne() {
      checkRequired();

      return (($('#quick_quote_step_1').find('input:invalid').length == 0) && ($('#quick_quote_step_1').find('select:invalid').length == 0))
    }

    function toggleRequestButton() {
      if (checkValidity()) {
        $('#submit-request-button').prop("disabled", false);
      } else {
        $('#submit-request-button').prop("disabled", true);
      }
    }

    function toggleNextButton() {
      if (checkStepOne()) {
        $('#quick-quote-next').prop("disabled", false);
      } else {
        $('#quick-quote-next').prop("disabled", true);
      }
    }

    function toggleMobileSlideVisibility(show_hide) {
      $landscape_slides = $('.carousel-inner').find('.landscape');
      $portrait_slides = $('.carousel-inner').find('.portrait');

      $('#carouselFeaturedIndicators .carousel-inner').find('div').each(function(i, e){
        if ($(e).hasClass('active')) {
          $(e).removeClass('active');
        }
      });

      if (show_hide === 'landscape'){
        $portrait_slides.removeClass('carousel-item').addClass('d-none');
        $landscape_slides.addClass('carousel-item').removeClass('d-none');
      } else if (show_hide === 'portrait') {
        $landscape_slides.removeClass('carousel-item').addClass('d-none');
        $portrait_slides.addClass('carousel-item').removeClass('d-none');
      }

      $('#carouselFeaturedIndicators .carousel-inner').find('.carousel-item').first().addClass('active');
    }

    var detectMobile = function detectMobile(){
      if (deviceWidth() > 991.98) {
        toggleMobileSlideVisibility('landscape');
      } else {
        toggleMobileSlideVisibility('portrait');
      }
    }

    $(window).on('resize', function(){
      detectMobile();
    });

    var resetDeepCleanOptions = function resetDeepCleanOptions() {
      $('#deep-clean-options').find('.spinner-value').each(function(i, e){
        $(e).val("0");
      });

      $('#deep-clean-options').find(':checkbox').each(function(i, e){
        $(e).prop("checked", false)
      });

      $('#deep-clean-options').find('.spinner-group :button').each(function(i, e){
        $(e).prop("disabled", true);
      });
    }

    $("#quick-quote-standard").click(function(e) {
      $("#quick-quote-deep").removeClass("active");
      $("#quick-quote-standard").addClass("active");
      resetDeepCleanOptions();
    });


    $('#deep-clean-options #clear-all-button').on('click', function() { 
      resetDeepCleanOptions();
    });

    $('#deep-clean-options :checkbox').on('click', function() {
      if (this.checked) {
        $($(this).parent().parent().parent().next().find('.spinner-button-inc')[0]).prop("disabled", false);
        $($(this).parent().parent().parent().next().find('.spinner-value')[0]).val("0");
      } else {
        $($(this).parent().parent().parent().next().find('.spinner-value')[0]).val("0");
        $(this).parent().parent().parent().next().find('.spinner-group :button').each(function(i, e) {
          $(e).prop("disabled", true);
        });
      }
    });

    resetDeepCleanOptions();
    detectMobile();
    toggleRequestButton();

    $('#custom-quote-form input').on('change', function() {
      toggleRequestButton();
    });

    $('#custom-quote-form select').on('change', function() {
      toggleRequestButton();
    });

    $('#quick-quote input').on('change', function() {
      toggleRequestButton();
      toggleNextButton();
    });

    $('#quick-quote select').on('change', function() {
      toggleRequestButton();
      toggleNextButton();
    });
  });

  $("#faq-load-more-btn").on('click', function(){
    $(this).addClass('d-none');
    $('#faq-additional').removeClass('d-none');
  });
});
